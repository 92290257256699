import { Button } from "@/components/ui/button"
import { ArrowRight, ShoppingCart, BarChart2, Truck, Stethoscope, Scale, Cpu } from "lucide-react"
import { Helmet } from "react-helmet"
import {Link} from "react-router-dom"


export default function UseCasesHome() {
  const useCases = [
    {
      icon: <ShoppingCart className="h-6 w-6" />,
      title: "E-commerce",
      description:
        "Learn how e-commerce and retail businesses use our platform to enhance their customer experience and streamline operations.",
      link: "/use-cases/ecommerce",
    },
    {
      icon: <BarChart2 className="h-6 w-6" />,
      title: "Finance & Legal",
      description:
        "See how financial and legal service providers use our platform to streamline communications and improve client interactions.",
      link: "/use-cases/finance-legal",
    },
    {
      icon: <Truck className="h-6 w-6" />,
      title: "Fleet Management",
      description:
        "Discover how fleet management companies leverage our platform to optimize their operations and improve driver communication.",
      link: "/use-cases/fleet-management",
    },
    {
      icon: <Stethoscope className="h-6 w-6" />,
      title: "Healthcare",
      description:
        "Learn how healthcare and pharmaceutical companies leverage Smler to simplify patient communication with secure link shortening.",
      link: "/use-cases/healthcare",
    },
    {
      icon: <Scale className="h-6 w-6" />,
      title: "Legal",
      description:
        "See how legal service providers use our platform to streamline client communications and document delivery",
      link: "/use-cases/legal",
    },
    {
      icon: <Cpu className="h-6 w-6" />,
      title: "Technology",
      description:
        "Discover how technology companies leverage our platform to streamline their operations and enhance user experience.",
      link: "/use-cases/technology",
    },
  ]

  return (
    <div className="container mx-auto px-4 py-16">
      <Helmet>
        <title>Industry-Specific Solutions with Smler</title>
        <meta name="description" content="Discover how our platform addresses unique challenges across various industries, driving innovation and efficiency with smler" />
      </Helmet>
      <h1 className="text-4xl font-bold text-center mb-4">Industry-Specific Solutions</h1>
      <p className="text-xl text-center text-muted-foreground mb-12">
        Discover how our platform addresses unique challenges across various industries, driving innovation and
        efficiency.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {useCases.map((useCase, index) => (
          <div key={index} className="border rounded-lg p-6 shadow-md">
            <div className="flex items-center mb-4">
              <div className="p-2 bg-primary/10 rounded-full">{useCase.icon}</div>
              <h2 className="text-2xl font-semibold ml-4">{useCase.title}</h2>
            </div>
            <p className="text-muted-foreground mb-6">{useCase.description}</p>
            <h3 className="font-semibold mb-2">Key Features:</h3>
            <ul className="list-disc list-inside mb-6 space-y-1">
              {/* {useCase.features.map((feature, featureIndex) => (
                <li key={featureIndex} className="text-muted-foreground">
                  {feature}
                </li>
              ))} */}
            </ul>
            <Link to={useCase.link}>
              <Button variant="outline" className="group">
                Explore {useCase.title} Solutions
                <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
              </Button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
