import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import { Provider } from 'react-redux';
import {store} from '@/store/store'
import { ApolloProvider } from '@apollo/client';
import client from '@/service/ApolloEnvironment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@/components/theme-provider"
import { BlogContent } from '@/blog/AllBlogs';
import LoadingComponent from '@/components/Loading';
import ErrorBoundary from '@/blog/ErrorBoundary';
import { H } from 'highlight.run';
import { adminRouter } from '@/admin';
import UseCasesHome from './layout/use-cases/use-cases-home';

const ContactUs = React.lazy(() => import('./layout/site/contact-us'));
const Pricing = React.lazy(() => import('./layout/site/pricing'));
const ClickDetails = React.lazy(() => import('./layout/analytics/click-details'));
const Admin = React.lazy(() => import('@/admin'));
const BulkShortUrl = React.lazy(() => import('@/layout/bulk-upload'));
const UsersList = React.lazy(() => import('@/account/settings/UsersList'));
const CustomDomains = React.lazy(() => import('@/layout/custom-domains'));
const AccountPage = React.lazy(() => import('@/account'));
const HomeComponent = React.lazy(() => import('@/layout/home/HomeComponent'));
const HomeSite = React.lazy(() => import('@/layout/site'));
const HomePage = React.lazy(() => import('@/layout/home'));
const TermsAndConditions = React.lazy(() => import('@/layout/site/terms-and-condition'));
const PrivacyPolicy = React.lazy(() => import('@/layout/site/privacy-policy'));
const Clicks = React.lazy(() => import('@/layout/clicks'));
const BillingPlan = React.lazy(() => import('@/account/settings/BillingPlan'));
const ProfileDetails = React.lazy(() => import('@/account/account-details'));
const ContactUsForm = React.lazy(() => import('@/layout/contact-us'));
const ShortedUrls = React.lazy(() => import('@/layout/home/ShortedUrls'));
const Referrer = React.lazy(() => import('@/layout/analytics'));
const ShortCodeNotFound = React.lazy(() => import('@/layout/short-code-not-found'));
const Blog = React.lazy(() => import('@/blog'));
const AllBlogs = React.lazy(() => import('@/blog/AllBlogs'));
const ErrorPage = React.lazy(() => import('@/ErrorPage'));
const LoginPage = React.lazy(() => import('@/account/login'));
const Register = React.lazy(() => import('@/account/register'));
const RootRouting = React.lazy(() => import('@/layout/root-routing'));
const Cancellations = React.lazy(() => import('@/layout/cancellations'));
const PaymentSuccess = React.lazy(() => import('@/account/payment/payment-success'));
const UseCases = React.lazy(() => import('@/layout/use-cases'));
const HealthcareUseCase = React.lazy(() => import('@/layout/use-cases/HealthCare'));
const EcommerceUseCase = React.lazy(() => import('@/layout/use-cases/Ecommerce'))
const FinancialLegalUseCase = React.lazy(() => import('@/layout/use-cases/FinanceLegal'))
const FleetManagementUseCase = React.lazy(() => import('@/layout/use-cases/FleetManagement'))
const LegalUseCase = React.lazy(() => import('@/layout/use-cases/Legal'))
const TechnologyUseCase = React.lazy(() => import('@/layout/use-cases/Technology'))


if(window.location.hostname === 'app.smler.in') {
  H.init('jdkxj0oe', {
    serviceName: "frontend-app",
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
        "http://localhost:3000"
      ],
    },
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeSite />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin",
    element: <Admin />,
    errorElement: <ErrorPage />,
    children: adminRouter,
  },
  {
    path: "/app",
    element: <HomePage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/app/contact-us",
        element: <ContactUsForm />
      },
      {
        path: "/app/urls",
        element: <ShortedUrls />
      },
      {
        path: "/app/bulk-short",
        element: <BulkShortUrl />
      },
      {
        path: "/app/branded-domains",
        element: <CustomDomains />
      },
      {
        path: "/app/root-routing",
        element: <RootRouting />
      },
      {
        path: "/app/account",
        element: <AccountPage />,
        children: [
          {
            path: "/app/account/plan",
            element: <BillingPlan />
          },
          {
            path: "/app/account/users",
            element: <UsersList />
          },
          {
            path: "/app/account",
            element: <ProfileDetails />
          }
        ]
      },
      {
        path: 'payment-success',
        element: <PaymentSuccess />
      },
      {
        path: "/app",
        element: <HomeComponent />
      },
      {
        path: "/app/clicks",
        element: <Clicks />
      },
      {
        path: "/app/analytics",
        element: <Referrer />
      },
      {
        path: "/app/analytics/clicks",
        element: <ClickDetails />
      }
    ]
  },
  {
    path: "/code-not-found",
    element: <ShortCodeNotFound />
  },
  {
    path: "/use-cases",
    element: <UseCases />,
    children: [
      {
        path: "",
        element: <UseCasesHome />
      },
      {
        path: "healthcare",
        element: <HealthcareUseCase />
      },
      {
        path: "ecommerce",
        element: <EcommerceUseCase />
      },
      {
        path: "finance-legal",
        element: <FinancialLegalUseCase />
      },
      {
        path: "fleet-management",
        element: <FleetManagementUseCase />
      },
      {
        path: "legal",
        element: <LegalUseCase />
      },
      {
        path: "technology",
        element: <TechnologyUseCase />
      }
    ]
  },
  {
    path: "/terms-and-condition",
    element: <TermsAndConditions />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "/account/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/account/register",
    element: <Register />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cancellations",
    element: <Cancellations />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/support",
    element: <ContactUs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/blog",
    element: <AllBlogs />,
    children: [
      {
        path: "/blog",
        element: <BlogContent />
      },
      {
        path: "/blog/:slug",
        element: <Blog />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider defaultTheme='dark'>
          <ErrorBoundary>
            <React.Suspense fallback={<LoadingComponent />}>
            <div>
              <RouterProvider router={router} />
              <ToastContainer />
            </div>
            </React.Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
